<template>
  <div id="order-view-action-cancel-and-edit">
    <b-button id="order-view-action-cancel-and-edit" v-ripple.400 class="mb-75" block :disabled="isNotEditable" @click.prevent="confirm = true">
      <feather-icon :icon="btnIcon || 'EditIcon'" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ btnText || $t('action.edit') }} </span>
    </b-button>

    <b-sidebar id="order-view-action-cancel-and-edit-confirm-sidebar" :visible="confirm" @change="confirm = $event">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ confirmTitle }} {{ $t('action.edition') }}</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <div class="p-2">
          <h4 class="mb-2">
            {{ confirmQuestion }}
          </h4>
          <p class="mb-4">{{ confirmText }}</p>
          <b-button
            id="order-view-action-cancel-and-edit-confirm-action-yes"
            type="button"
            variant="outline-secondary"
            block
            class="w-100"
            @click.prevent="cancelAndEdit(), hide()"
          >
            {{ $t('common.yes') }}
          </b-button>
          <b-button
            id="order-view-action-cancel-and-edit-confirm-action-no"
            type="button"
            variant="outline-secondary"
            block
            class="w-100"
            @click.prevent="hide"
          >
            {{ $t('common.no') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { patchQuoteCancelAndEditRequest } from '@/request/globalApi/requests/quoteRequests'
// import { patchQuoteCancelAndEditRequest, patchQuoteCancelAndRecallRequest } from '@/request/globalApi/requests/quoteRequests'

export default {
  name: 'OrderViewActionCancelAndEdit',

  props: {
    btnText: {
      type: String,
      default: '',
    },

    btnIcon: {
      type: String,
      default: '',
    },

    confirmTitle: {
      type: String,
      default: '',
    },

    confirmQuestion: {
      type: String,
      default: '',
    },

    confirmText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      confirm: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
    ...mapFields('quote', ['quoteLoading']),

    isNotEditable() {
      const quoteLineStatusValid = line => line.status !== 'in_progress' || line.status !== 'delivered'
      if (!this.quoteSelected.quote.lines.every(quoteLineStatusValid)) return true
      return false
    },
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    cancelAndEdit() {
      this.quoteLoading = true

      patchQuoteCancelAndEditRequest(this.quoteSelected.quote.id, { orderUrl: window.location.href },  this.$store.getters['quote/quoteTitleAndRef'])
        .then(() => {
          this.$router.push({
            name: 'shop-checkout',
            params: {
              provider_id: this.quoteSelected.checkout.providerOrganizationId,
              trip_id: this.quoteSelected.checkout.tripId,
              step_id: this.quoteSelected.checkout.stepId,
              event_id: this.quoteSelected.checkout.stepEventId,
              airport_id: this.quoteSelected.checkout.airportId,
            },
          })
        })
        .finally(() => {
          this.quoteLoading = false
        })
    },
  },
}
</script>
